<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="350">
      <v-card class="card">
        <v-card-title> </v-card-title>
        <div
          class="primary--text text-center text-16 font-500 px-2"
          v-html="message"
        ></div>
        <div class="action d-flex justify-center pt-5 pb-7">
          <v-btn
            text
            @click="handleClick(false)"
            class="detail btn text-16 font-500"
            >ไม่ใช่</v-btn
          >
          <v-btn
            text
            class="collect primary--bg btn text-16 font-500"
            @click="handleClick"
            >ใช่</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick(e) {
      if (e) {
        this.$emit("submit");
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.detail {
  background: #cecece;
  color: #757575;
}
.collect {
  color: white;
}
.action {
  column-gap: 1rem;
}
</style>
