/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Membership } from '../models/Membership';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MembershipService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Membership Return Membership object
     * @throws ApiError
     */
    public getMembership({
        domain,
    }: {
        domain: string,
    }): CancelablePromise<Membership> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}',
            path: {
                'domain': domain,
            },
        });
    }

    /**
     * @returns Membership Return Empty Object
     * @throws ApiError
     */
    public verifyMembershipRenewCode({
        domain,
        code,
    }: {
        domain: string,
        code: string,
    }): CancelablePromise<Membership> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/renew',
            path: {
                'domain': domain,
            },
            query: {
                'code': code,
            },
        });
    }

    /**
     * @returns Membership Return Membership object
     * @throws ApiError
     */
    public renewMembership({
        domain,
        requestBody,
    }: {
        domain: string,
        requestBody: {
            code: string;
        },
    }): CancelablePromise<Membership> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/membership/{domain}/renew',
            path: {
                'domain': domain,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
