/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { ConsentService } from './services/ConsentService';
import { MembershipService } from './services/MembershipService';
import { MembershipBabyService } from './services/MembershipBabyService';
import { MembershipCardService } from './services/MembershipCardService';
import { MembershipCouponService } from './services/MembershipCouponService';
import { MembershipCouponGiveawayService } from './services/MembershipCouponGiveawayService';
import { MembershipHistoryService } from './services/MembershipHistoryService';
import { MembershipHostService } from './services/MembershipHostService';
import { MembershipPointService } from './services/MembershipPointService';
import { OtpService } from './services/OtpService';
import { UserService } from './services/UserService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class UserApi {

    public readonly consent: ConsentService;
    public readonly membership: MembershipService;
    public readonly membershipBaby: MembershipBabyService;
    public readonly membershipCard: MembershipCardService;
    public readonly membershipCoupon: MembershipCouponService;
    public readonly membershipCouponGiveaway: MembershipCouponGiveawayService;
    public readonly membershipHistory: MembershipHistoryService;
    public readonly membershipHost: MembershipHostService;
    public readonly membershipPoint: MembershipPointService;
    public readonly otp: OtpService;
    public readonly user: UserService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.consent = new ConsentService(this.request);
        this.membership = new MembershipService(this.request);
        this.membershipBaby = new MembershipBabyService(this.request);
        this.membershipCard = new MembershipCardService(this.request);
        this.membershipCoupon = new MembershipCouponService(this.request);
        this.membershipCouponGiveaway = new MembershipCouponGiveawayService(this.request);
        this.membershipHistory = new MembershipHistoryService(this.request);
        this.membershipHost = new MembershipHostService(this.request);
        this.membershipPoint = new MembershipPointService(this.request);
        this.otp = new OtpService(this.request);
        this.user = new UserService(this.request);
    }
}

