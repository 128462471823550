<template>
  <v-app>
    <v-main :class="[{ main: layout === 'white' }]">
      <Navbar />
      <router-view />
    </v-main>
    <div class="text-center" style="background-color: black">
      <v-overlay :absolute="true" :opacity="0.8" :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import { mapState, mapActions } from "vuex";

export default {
  name: "App",

  components: {
    Navbar,
  },
  data: () => ({
    //
  }),
  watch: {
    $route(to) {
      this.checkRoute(to.path);
    },
    onDomain(domain) {
      if (domain === "prestige") {
        this.$vuetify.theme.themes["light"] = {
          primary: "#2B4357",
          primaryLight: "#3D8AD1",
          backgroundGradient: "#3D8AD1",
        };
      } else if (domain === "all-you") {
        this.$vuetify.theme.themes["light"] = {
          primary: "#006C5B",
          primaryLight: "#70C793",
          backgroundGradient: "#70C793 ",
        };
      } else if (domain === "mom-baby") {
        this.$vuetify.theme.themes["light"] = {
          primary: "#009C97",
          primaryLight: "#A2CCDA",
          backgroundGradient: "#A2CCDA",
          primaryInformation: "#E9F6F5"
        };
      } else {
        this.$vuetify.theme.themes["light"] = {
          primary: "#397D54",
          primaryLight: "#397D54",
          backgroundGradient: "#73C088",
          primaryInformation: "#ECF2EE"
        };
      }
    },
  },
  computed: {
    ...mapState("Auth", ["auth"]),
    ...mapState("Auth", ["user"]),
    ...mapState("Request", ["requestApi"]),
    ...mapState("Layout", ["onDomain"]),
    isLoading() {
      return this.$wait.is("app loading");
    },
    layout() {
      return this.$route.meta.layout;
    },
  },
  methods: {
    ...mapActions({
      checkRoute: "Layout/checkRoute",
      developing: "Auth/develop",
      setLineUser: "Auth/setLineUser",
      setUserProfile: "Auth/setUserProfile",
      setRequest: "Request/setRequest",
    }),
    async autoLogin() {
      this.$wait.start("app loading");
      try {
        let profile;
        let accessToken;
        profile = await this.$liff.getProfile();
        accessToken = await this.$liff.getAccessToken();
        // console.log(accessToken);
        this.setLineUser({ accessToken, profile });
        this.setRequest();
        const userProfile = await this.requestApi.user.getUser({
          prefillPackageEventCode: undefined,
        });
        this.setUserProfile({ user: userProfile, isLoggedIn: true });
        // this.$router.replace({
        //   path: "/membership",
        // });
      } catch (e) {
        console.log("error", e);
        const userProfile = await this.requestApi.user.createUser();
        this.setUserProfile({ user: userProfile, isLoggedIn: true });
      } finally {
        this.$router.start();
        this.$wait.end("app loading");
      }
    },
    async initApp() {
      console.log("env", JSON.stringify(process.env.NODE_ENV));
      let onDev = false;
      if (!onDev) {
        let liff_Id = "";
        if (process.env.NODE_ENV === "production") {
          liff_Id = "1653712040-jaePLYbz";
        } else if (process.env.NODE_ENV === "preview") {
          liff_Id = "1653712040-jaePLYbz";
        } else {
          liff_Id = "1653712040-L0ZW6vOj";
        }

        this.$liff
          .init({
            liffId: liff_Id,
          })
          .then(() => {
            if (!this.$liff.isLoggedIn()) {
              this.$liff.login();
            } else {
              this.autoLogin();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.developing();
        this.setRequest();
        this.$router.start();
      }
    },
  },
  async created() {
    await this.initApp();
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/style.scss";
.main {
  background-color: #f2f2f2;
}
</style>
