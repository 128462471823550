<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="350">
      <v-card class="card">
        <v-card-title
          class="primary-gradient--bg bg-color d-flex justify-space-around pa-3"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-20 text-center"
            >แลกคะแนนส่วนลด</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="bg-icon" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="d-flex justify-center pt-4 px-5">
          <div class="information-group">
            <div class="information">
              <img class="card-img" :src="card.image" />
              <div class="card-info mt-2">
                <v-row
                  class="text-12"
                  style="color: rgba(150, 149, 149, 1)"
                  no-gutters
                >
                  <v-col cols="3">คะแนนสะสม</v-col>
                  <v-col cols="1">:</v-col>
                  <v-col
                    class="font-500 text-16"
                    style="color: rgba(117, 117, 117, 1)"
                    cols="8"
                    >{{ score.toLocaleString() }}</v-col
                  >
                </v-row>
                <v-row
                  class="text-12"
                  style="color: rgba(150, 149, 149, 1)"
                  no-gutters
                >
                  <v-col cols="3">วันที่หมดอายุ</v-col>
                  <v-col cols="1">:</v-col>
                  <v-col
                    class="font-500 text-16"
                    style="color: rgba(117, 117, 117, 1)"
                    cols="8"
                    >{{ toDate(card.expiration_date) }}</v-col
                  >
                </v-row>
              </div>
              <v-divider class="my-4"></v-divider>
              <div class="slide-group">
                <div class="message">
                  <div class="text-14 font-500 primary--text">
                    กรุณาระบุคะแนนหรือจำนวนเงินที่ต้องการแลก
                  </div>
                  <div class="text-12" style="color: rgba(150, 149, 149, 1)">
                    ระบบจะทำการคำนวนคะแนนด้วย 1 คะแนน = 1 บาท<br />
                    คุณสามารถทำการแลกส่วนลดค่ารักษาได้สูงสุด
                    {{ score.toLocaleString() }} บาท
                  </div>
                </div>
                <div class="mt-5 mb-9" v-if="active">
                  <v-row style="color: rgba(150, 149, 149, 1)" no-gutters>
                    <v-col class="font-500 text-14" cols="5">คะแนนสะสม</v-col>
                    <v-col cols="2"></v-col>
                    <v-col class="font-500 text-14" cols="5"
                      >ส่วนลดค่ารักษา</v-col
                    >
                  </v-row>
                  <v-row
                    class="mt-1 mb-3"
                    style="color: rgba(150, 149, 149, 1)"
                    no-gutters
                  >
                    <v-col cols="12" class="score-group d-flex justify-center">
                      <div class="field-input">
                        <v-text-field
                          v-model="transferPoint"
                          hide-details="auto"
                          single-line
                          type="number"
                          suffix="คะแนน"
                          outlined
                          rounded
                          dense
                          required
                        ></v-text-field>
                      </div>
                      <div
                        class="d-flex justify-center align-center text-20 font-700"
                      >
                        =
                      </div>
                      <div class="field-input">
                        <v-text-field
                          :label="transferPoint"
                          disabled
                          hide-details="auto"
                          suffix="บาท"
                          outlined
                          rounded
                          dense
                          required
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <div
                    class="mb-2 text-center error--text text-12"
                    v-show="isOutofRange"
                  >
                    ไม่สามารถแลกพ้อยได้
                  </div>
                  <div class="d-flex align-center justify-center">
                    <v-btn
                      text
                      @click="usePoint()"
                      class="primary--bg transfer-text d-flex align-center justify-center white--text font-500"
                    >
                      แลกคะแนน
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "usepoint",
  data() {
    return {
      closeicon: require("@/assets/icon/closeicon.png"),
      arrowLeft: require("@/assets/icon/arrowleft.png"),
      card: null,
      transferPoint: null,
      isOutofRange: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    score: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    onDomain() {
      this.fetchCard();
    },
  },
  computed: {
    ...mapState("Request", ["requestApi"]),
    ...mapState("Auth", ["user"]),
    ...mapState("Layout", ["onDomain"]),
    titleByDomain() {
      if (this.onDomain === "be") {
        return "Be Phyathai 3 Family";
      } else if (this.onDomain === "prestige") {
        return "Long Term Prestige";
      } else if (this.onDomain === "all-you") {
        return "All You Can Check";
      } else {
        return "Mommy & Baby Club";
      }
    },
  },
  methods: {
    fetchCard() {
      this.card = this.user.memberships.filter(
        (e) => e.domain === this.onDomain
      )[0];
    },
    async usePoint() {
      if (
        this.transferPoint === null ||
        parseFloat(this.transferPoint) > this.score ||
        parseFloat(this.transferPoint) <= 0
      ) {
        this.isOutofRange = true;
        return;
      }
      this.isOutofRange = false;
      this.$emit("usedPoint", parseFloat(this.transferPoint));
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    },
  },
  created() {
    this.fetchCard();
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.message {
  color: #235d3a;
}
.bg-color {
  color: #ffffff;
}

.score-group {
  column-gap: 10px;
}

.bg-icon {
  background: #fff;
}

.transfer-text {
  width: 135px;
  height: 40px !important;
  letter-spacing: 0.4px;
  border-radius: 20px;
}

.card-img {
  object-fit: cover;
  border-radius: 20px;
  width: 300px;
  height: 185px;
}
</style>
