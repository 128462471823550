/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Membership_Points } from '../models/Membership_Points';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MembershipPointService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Membership_Points Return Membership_Points object
     * @throws ApiError
     */
    public getMembershipPoint({
        domain,
    }: {
        domain: string,
    }): CancelablePromise<Membership_Points> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/points',
            path: {
                'domain': domain,
            },
        });
    }

    /**
     * @returns Membership_Points Return Membership_Points object
     * @throws ApiError
     */
    public useMembershipPoint({
        domain,
        requestBody,
    }: {
        domain: string,
        requestBody: {
            value: number;
        },
    }): CancelablePromise<Membership_Points> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/membership/{domain}/points/use',
            path: {
                'domain': domain,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
