/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Membership_MembershipCoupon } from '../models/Membership_MembershipCoupon';
import type { Membership_MembershipCoupons } from '../models/Membership_MembershipCoupons';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MembershipCouponService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Membership_MembershipCoupons Return MembershipCoupon object
     * @throws ApiError
     */
    public getMembershipCoupons({
        domain,
    }: {
        domain: string,
    }): CancelablePromise<Membership_MembershipCoupons> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/coupons',
            path: {
                'domain': domain,
            },
        });
    }

    /**
     * @returns Membership_MembershipCoupon Return MembershipCoupon object
     * @throws ApiError
     */
    public getMembershipCoupon({
        domain,
        membershipCouponId,
    }: {
        domain: string,
        membershipCouponId: string,
    }): CancelablePromise<Membership_MembershipCoupon> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/coupons/{membership_coupon_id}',
            path: {
                'domain': domain,
                'membership_coupon_id': membershipCouponId,
            },
        });
    }

    /**
     * @returns Membership_MembershipCoupon Return MembershipCoupon object
     * @throws ApiError
     */
    public useMembershipCoupon({
        domain,
        membershipCouponId,
    }: {
        domain: string,
        membershipCouponId: string,
    }): CancelablePromise<Membership_MembershipCoupon> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/membership/{domain}/coupons/{membership_coupon_id}/use',
            path: {
                'domain': domain,
                'membership_coupon_id': membershipCouponId,
            },
        });
    }

}
