/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Membership_HistoryLog } from '../models/Membership_HistoryLog';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MembershipHistoryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Membership_HistoryLog Return Membership_Host object
     * @throws ApiError
     */
    public getMembershipHistory({
        domain,
    }: {
        domain: string,
    }): CancelablePromise<Array<Membership_HistoryLog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/history',
            path: {
                'domain': domain,
            },
        });
    }

}
