const authModule = {
  namespaced: true,
  state: {
    auth: {
      isLoggedIn: false,
      token: null,
    },
    user: {
      id: "",
      code: "",
      memberships: [
        {
          id: "",
          user_id: "",
          domain: "",
          pending_purchase_approval_request: true,
        },
      ],
      consent_profile: {
        consents: [
          {
            id: "",
            name: "",
            content: "",
            accepted: true,
          },
        ],
      },
      general_profile: {
        first_name: "",
        last_name: "",
        birth_date: 0,
        mobile: "",
        gender: "",
        email: "",
        citizen_id: "",
      },
      hospital_profile: {
        hospital_number: "",
        previous_pyathai_customer: true,
      },
      address_profile: {
        address1: "",
        address2: "",
        road: "",
        district: "",
        sub_district: "",
        province: "",
        postcode: 0,
      },
    },
  },
  mutations: {
    SET_AUTH(state, value) {
      state.auth = {
        isLoggedIn: value.isLoggedIn || state.auth.isLoggedIn,
        token: value.token || state.auth.token,
        profile: value.profile || state.auth.profile,
      };
    },
    SET_USER(state, value) {
      Object.keys(value).forEach(function (key) {
        state.user[key] = value[key];
      });
    },
  },
  actions: {
    develop(context) {
      context.commit("SET_AUTH", {
        isLoggedIn: true,
        token: null,
      });
    },
    setLineUser({ commit }, payload) {
      commit("SET_AUTH", {
        isLoggedIn: false,
        token: payload.accessToken,
        profile: payload.profile,
      });
    },
    setUserProfile({ commit }, payload) {
      commit("SET_USER", payload.user);
      commit("SET_AUTH", {
        isLoggedIn: payload.isLoggedIn,
      });
    },
  },
};
export default authModule;
