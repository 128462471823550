/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OTP } from '../models/OTP';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OtpService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns OTP Return OTP object
     * @throws ApiError
     */
    public requestOtp({
        requestBody,
    }: {
        requestBody: {
            mobile: string;
        },
    }): CancelablePromise<OTP> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/otp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OTP Return OTP object
     * @throws ApiError
     */
    public verifyOtp({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            otp: string;
        },
    }): CancelablePromise<OTP> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/otp/{id}/verify',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
