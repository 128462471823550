import { extend, localize } from 'vee-validate'
import { required, digits, length, regex, max, min, email, numeric } from 'vee-validate/dist/rules'

extend('regex', regex)
extend('required', required)
extend('digits', digits)
extend('length', length)
extend('max', max)
extend('min', min)
extend('email', email)
extend('numeric', numeric)

localize({
  en: {
    fields: {
      mobile: {
        required: 'กรุณากรอก เบอร์โทรศัพท์',
        regex: 'กรุณากรอก เบอร์โทรศัพท์ให้ถูกต้อง',
        max: 'กรุณากรอก เบอร์โทรศัพท์ 10 หลัก',
      },
      firstName:{
        required: 'กรุณากรอก ชื่อจริง',
        regex: 'ชื่อภาษาไทยและอังกฤษเท่านั้นห้ามใส่เลขและสัญลักษณ์'
      },
      lastName:{
        required: 'กรุณากรอก นามสกุล',
        regex: 'ชื่อภาษาไทยและอังกฤษเท่านั้นห้ามใส่เลขและสัญลักษณ์'
      },
      gender:{
        required: 'กรุณาระบุ เพศ',
      },
      datebirthday: {
        required: 'กรุณากรอก วัน / เดือน / ปี เกิด'
      },
      email: {
        email: 'กรุณากรอก อีเมล ให้ถูกต้อง'
      },
      district: {
        required: 'กรุณากรอก เขต / อำเภอ'
      },
      province: {
        required: 'กรุณากรอก จังหวัด'
      },
      postcode:{
        required: 'กรุณากรอก รหัสไปรษณีย์',
        regex: 'กรุณากรอก รหัสไปรษณีย์ให้ถูกต้อง',
         max: 'กรุณากรอก รหัสไปรษณีย์ให้ถูกต้อง',
         min: 'กรุณากรอก รหัสไปรษณีย์ให้ถูกต้อง',
      },
      sub_district:{
        required: 'กรุณากรอก แขวง / ตำบล'
      },
      road:{
        required: 'กรุณากรอก ถนน'
      },
      address:{
        required: 'กรุณากรอก ที่อยู่'
      },
      textField:{
        required: 'โปรดระบุรายละเอียด'
      },
    }
  }
});