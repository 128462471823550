import dayjs from "dayjs";
import Vue from "vue";
import "dayjs/locale/th";

Vue.mixin({
  methods: {
    toTimestamp(date) {
      return parseInt(dayjs(date).unix()) * 1000;
    },
    toDate(timestamp, format) {
      let dateFormat = format ? format : "DD MMMM YYYY";
      return dayjs(timestamp).locale("th").add(543, "year").format(dateFormat);
    },
    toDateDOB(timestamp) {
      return dayjs(timestamp).format("YYYY-MM-DD");
    },
    toDateDOBth(timestamp) {
      return dayjs(timestamp)
        .locale("th")
        .add(543, "year")
        .format("DD / MMMM / YYYY");
    },
  },
});
