/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Membership_Coupon } from '../models/Membership_Coupon';
import type { Membership_Coupons } from '../models/Membership_Coupons';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MembershipCouponGiveawayService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Membership_Coupons Return Membership_Coupons object
     * @throws ApiError
     */
    public getMembershipCouponGiveaways({
        domain,
    }: {
        domain: string,
    }): CancelablePromise<Membership_Coupons> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/coupon_giveaways',
            path: {
                'domain': domain,
            },
        });
    }

    /**
     * @returns Membership_Coupon Return Membership_Coupon object
     * @throws ApiError
     */
    public getMembershipCouponGiveaway({
        domain,
        couponId,
    }: {
        domain: string,
        couponId: string,
    }): CancelablePromise<Membership_Coupon> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/coupon_giveaways/{coupon_id}',
            path: {
                'domain': domain,
                'coupon_id': couponId,
            },
        });
    }

    /**
     * @returns Membership_Coupon Return Membership_Coupon object
     * @throws ApiError
     */
    public claimMembershipCouponGiveaway({
        domain,
        couponId,
    }: {
        domain: string,
        couponId: string,
    }): CancelablePromise<Membership_Coupon> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/membership/{domain}/coupon_giveaways/{coupon_id}/claim',
            path: {
                'domain': domain,
                'coupon_id': couponId,
            },
        });
    }

}
