/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Membership_Card } from '../models/Membership_Card';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MembershipCardService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Membership_Card Return Membership_Card object
     * @throws ApiError
     */
    public getMembershipCard({
        domain,
    }: {
        domain: string,
    }): CancelablePromise<Membership_Card> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/card',
            path: {
                'domain': domain,
            },
        });
    }

    /**
     * @returns Membership_Card Return Membership object
     * @throws ApiError
     */
    public useMembershipCard({
        domain,
    }: {
        domain: string,
    }): CancelablePromise<Membership_Card> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/membership/{domain}/card/use',
            path: {
                'domain': domain,
            },
        });
    }

}
