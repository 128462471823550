const layoutModule = {
  namespaced: true,
  state: {
    showProfile: true,
    onDomain: "be",
  },
  mutations: {
    SET_SHOW_PROFILE(state, value) {
      state.showProfile = value;
    },
    SET_ON_CARD(state, value) {
      state.onDomain = value;
    },
  },

  actions: {
    checkRoute({ commit }, path) {
      const pathShowProfile = ["/", "/home"];
      if (pathShowProfile.some((e) => e === path.toLowerCase())) {
        commit("SET_SHOW_PROFILE", true);
        return;
      }
      commit("SET_SHOW_PROFILE", false);
    },
    setonDomain({ commit }, card){
      commit("SET_ON_CARD", card);
    }
  },
};
export default layoutModule;
