import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: "#397D54",
        primaryLight: "#397D54",
        backgroundGradient: "#73C088",
        primaryInformation: "#ECF2EE"
      },
    },
  },
});
