/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Membership_Host } from '../models/Membership_Host';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MembershipHostService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Membership_Host Return Membership_Host object
     * @throws ApiError
     */
    public getMembershipHost({
        domain,
    }: {
        domain: string,
    }): CancelablePromise<Membership_Host> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/host',
            path: {
                'domain': domain,
            },
        });
    }

    /**
     * @returns Membership_Host Return Membership_Host object
     * @throws ApiError
     */
    public addMembershipHostMember({
        domain,
        requestBody,
    }: {
        domain: string,
        requestBody: {
            user_code: string;
        },
    }): CancelablePromise<Membership_Host> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/membership/{domain}/host/add',
            path: {
                'domain': domain,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
