import { UserApi } from "../client-sdk/user-api";

const requestModule = {
  namespaced: true,
  state: {
    requestApi: null,
  },
  mutations: {
    SET_REQUEST(state, f) {
      state.requestApi = f;
    },
  },
  actions: {
    setRequest({
      commit,
      rootState: {
        Auth: { auth },
      },
    }) {
      let apiAddress;
      if (process.env.NODE_ENV === "production") {
        apiAddress = "https://phyathai-user-api.plapingonline.com";
        if (process.env.VUE_APP_PT3_SERVER_URL) {
          apiAddress = process.env.VUE_APP_PT3_SERVER_URL;
        }
      } else if (process.env.NODE_ENV === "preview") {
        apiAddress = "https://phyathai-user-api.plapingonline.com";
      } else {
        apiAddress = "https://phyathai-user-api.plapingonline.com";
        // apiAddress = "http://localhost:5051";
      }
      // console.log(auth.token);
      const request = new UserApi({
        BASE: apiAddress,
        HEADERS: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      commit("SET_REQUEST", request);
    },
  },
};
export default requestModule;
