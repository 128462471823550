/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Membership_Coupon = {
    id?: string;
    thumbnail_image_url?: string | null;
    category?: string;
    section?: string;
    name?: string;
    description?: string;
    type?: string;
    term_condition?: string;
    expiration_mode?: Membership_Coupon.expiration_mode;
    expiration?: number;
    domain?: string;
    usage_limit?: number;
    remains?: number | null;
    giveaway?: boolean;
    claimed?: boolean;
};

export namespace Membership_Coupon {

    export enum expiration_mode {
        DURATION = 'duration',
        DATE = 'date',
    }


}

