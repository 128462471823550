export default function auth({ next, store, to }) {
  const { auth, user } = store.state.Auth;

  const userMember = user.memberships.some(
    (member) => member.pending === false || member.domain === "be"
  );
  // console.log('Dont forget auth userMember')

  // if (!auth.isLoggedIn) {
  //   return next({
  //     name: "membership",
  //     query: { redirect: to.fullPath },
  //     replace: true,
  //   });
  // }
  if (!auth.isLoggedIn || !userMember) {
    return next({
      name: "membership",
      query: { redirect: to.fullPath },
      replace: true,
    });
  }
  return next();
}
