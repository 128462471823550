/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns User Return User object
     * @throws ApiError
     */
    public getUser({
        prefillPackageEventCode,
    }: {
        prefillPackageEventCode?: string,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user',
            query: {
                'prefill_package_event_code': prefillPackageEventCode,
            },
        });
    }

    /**
     * @returns User Return User object
     * @throws ApiError
     */
    public createUser(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user',
        });
    }

    /**
     * @returns User Return User object
     * @throws ApiError
     */
    public updateUser({
        requestBody,
        otpId,
    }: {
        requestBody: User,
        otpId?: string,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user',
            query: {
                'otp_id': otpId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
