import Vue from "vue";
import App from "./App.vue";
import VueWait from "vue-wait";
import "./assets/scss/style.scss";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";

import dragVerify from 'vue-drag-verify'
import VueThailandAddress from "vue-thailand-address";
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import "./validate";
import "./plugins/mixinMethods";

Vue.config.productionTip = false;
Vue.prototype.$liff = window.liff;

Vue.use(VueWait);
Vue.use(VueThailandAddress);

Vue.component('DragVerify', dragVerify)

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

const initializeApp = () => {
  new Vue({
    router,
    store,
    vuetify,
    wait: new VueWait(),
    render: (h) => h(App),
  }).$mount("#app");
};
initializeApp();
