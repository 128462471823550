/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Consent } from '../models/Consent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConsentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Consent Return Consent object
     * @throws ApiError
     */
    public getConsent({
        id,
    }: {
        id: string,
    }): CancelablePromise<Consent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/consents/{id}',
            path: {
                'id': id,
            },
        });
    }

}
