/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type User_GeneralProfile = {
    first_name?: string;
    last_name?: string;
    birth_date?: number;
    mobile?: string;
    gender?: User_GeneralProfile.gender;
    email?: string;
    citizen_id?: string;
};

export namespace User_GeneralProfile {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

