import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import auth from "@/middleware/auth";
import middlewarePipeline from "@/middleware/middlewarePipeline";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '*',
  //   name: 'NotFound',
  //   component: () => import('../views/Home.vue'),
  //   meta: {
  //     layout: 'white',
  //     middleware: [
  //       auth
  //     ],
  //   }
  // },
  {
    path: "/",
    alias: ["/home"],
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      layout: "white",
      middleware: [auth],
    },
  },
  {
    path: "/membership",
    name: "membership",
    component: () => import("../views/MemberShip.vue"),
    meta: {
      layout: "white",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register.vue"),
    meta: {
      layout: "white",
    },
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/UserInformation.vue"),
    meta: {
      layout: "white",
      middleware: [auth],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});
const promise = new Promise((resolve) => {
  router.start = resolve;
});

router.beforeEach(async (to, from, next) => {
  await promise;
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
