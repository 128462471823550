<template>
  <div v-if="auth.isLoggedIn" class="navbar primary-gradient--bg">
    <div class="d-flex justify-space-between">
      <div class="phyathi-logo">
        <img width="100%" :src="phyathaiLogo" alt="phyathai-logo" />
      </div>
      <v-btn icon class="home-logo primary--bg px-6 py-2" link to="/membership">
        <img width="25px" height="25px" :src="homeIcon" alt="home icon" />
      </v-btn>
    </div>
    <div
      v-if="showProfile && user.general_profile.mobile"
      class="user-profile mt-7 d-flex"
    >
      <div
        class="user-display-group primary-gradient--bg d-flex justify-center align-center"
        @click="goUser()"
      >
        <img :src="auth.profile.pictureUrl" class="user-display" />
      </div>
      <div class="ml-3 user-info white--text">
        <div class="text-14">สวัสดี คุณ</div>
        <div class="user-profile-name text-20 font-500 mb-1">
          {{ user.general_profile.first_name }}
          {{ user.general_profile.last_name }}
        </div>
        <div v-if="onDomain !== 'prestige'" class="d-flex">
          <div class="user-referral text-16">ID : {{ user.code }}</div>
          <v-btn
            icon
            class="ml-3 copy-referral pa-5 rounded-circle"
            @click="copyID(user.code)"
          >
            <img :src="copyIcon" width="16px" />
          </v-btn>
        </div>
        <div
          v-else
          class="d-flex align-center"
          :class="{
            'mb-6': showSuccessModal === true || showUsePoint === true,
          }"
        >
          <div class="score-title text-14 mr-3">คะแนน</div>
          <div class="score-group d-flex">
            <div
              class="score d-flex align-center pl-5 text-20"
              :style="
                score.toString().length >= 6
                  ? 'font-size:12px'
                  : 'font-size:14px'
              "
            >
              {{ score.toLocaleString() }}
            </div>
            <v-btn
              text
              @click="showUsePoint = true"
              class="text-14 font-500 trasfer-btn d-flex align-center d-flex align-center justify-center"
            >
              แลกคะแนน
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <UsePoint
      v-if="showUsePoint"
      :active="showUsePoint"
      :score="score"
      @usedPoint="confirmModal"
      @close="showUsePoint = false"
    />
    <ConfirmModal
      v-if="showConfirmModal"
      :active="showConfirmModal"
      :message="confirmMessage"
      @submit="usedPoint"
      @close="showConfirmModal = false"
    />
    <SuccessModal
      v-if="showSuccessModal"
      :active="showSuccessModal"
      :message="successMessage"
      @submit="showSuccessModal = false"
    />
  </div>
</template>

<script>
import UsePoint from "@/components/dialog/UsePoint";
import SuccessModal from "@/components/modal/SuccessModal";
import ConfirmModal from "@/components/modal/ConfirmModal";

import { mapActions, mapState } from "vuex";

export default {
  name: "navbar",
  components: {
    UsePoint,
    SuccessModal,
    ConfirmModal,
  },

  data: () => ({
    homeIcon: require("@/assets/icon/home.png"),
    copyIcon: require("@/assets/icon/copy.png"),
    phyathaiLogo: require("@/assets/img/phyathai-logo-white.png"),
    userDisplay: require("@/assets/img/mockDisplay.png"),
    score: 0,
    showUsePoint: false,
    showSuccessModal: false,
    showConfirmModal: false,
    confirmMessage: "",
    successMessage: {
      title: "แลกคะแนนเรียบร้อย",
      description: "แลกคะแนนส่วนลดค่ารักษาพยาบาลสำเร็จ",
    },
    transferPoint: 0,
  }),
  computed: {
    ...mapState("Request", ["requestApi"]),
    ...mapState("Layout", ["showProfile"]),
    ...mapState("Auth", ["user"]),
    ...mapState("Auth", ["auth"]),
    ...mapState("Layout", ["onDomain"]),
  },
  watch: {
    requestApi() {
      this.getScore();
    },
    onDomain(domain) {
      if (domain === "prestige") {
        this.getScore();
      }
    },
  },

  methods: {
    ...mapActions({
      setonDomain: "Layout/setonDomain",
    }),
    confirmModal(point) {
      this.showConfirmModal = true;
      this.transferPoint = point;
      this.confirmMessage = `คุณต้องการแลกคะแนน <b class='text-18'>${point.toLocaleString()} คะแนน</b><br/>เพื่อใช้เป็นส่วนลดค่ารักษาพยาบาล ใช่หรือไม่ ?`;
    },
    copyID(code) {
      navigator.clipboard.writeText(code);
    },
    async usedPoint() {
      this.showConfirmModal = false
      try {
        this.$wait.start("app loading");
        this.showUsePoint = false;
        await this.requestApi.membershipPoint.useMembershipPoint({
          domain: this.onDomain,
          requestBody: {
            value: parseFloat(this.transferPoint),
          },
        });
        await this.getScore();
        this.showSuccessModal = true;
      } catch (err) {
        alert("เกิดข้อผิดพลาด")
      } finally {
        this.$wait.end("app loading");
      }
    },
    async getScore() {
      try {
        this.$wait.start("app loading");
        this.showConfirmUseCardModal = false;
        const score = await this.requestApi.membershipPoint.getMembershipPoint({
          domain: "prestige",
        });
        this.score = score.value;
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    goUser() {
      this.$router.replace("/user");
    },
  },
  // created() {
  //   this.getScore();
  // },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 4rem 1rem 1.2rem 1rem;
  border-radius: 0px 0px 40px 40px;
}
.phyathi-logo {
  width: 230px;
}
.home-logo {
  width: fit-content;
  height: fit-content;
  border-radius: 22px;
}

.user-display-group {
  border-radius: 100%;
  border: 2px solid #ffffff;
  width: 90px;
  height: 90px;
}
.user-display {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}
.user-referral {
  padding: 0.5em 1em;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(57, 125, 84, 0.5);
  border-radius: 20px;
  width: 11rem;
}

.copy-referral {
  background: #ffffff;
}
.score-group {
  height: 40px;
}
.score {
  width: 108px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(43, 67, 87, 0.5);
  border-radius: 20px 0px 0px 20px;
}
.trasfer-btn {
  width: 96px;
  height: inherit !important;
  background: #ffffff;
  border: 1px solid rgba(43, 67, 87, 0.8);
  border-left: 0;
  border-radius: 0px 20px 20px 0px;
  color: black;
  letter-spacing: 0.4px;
}
</style>
