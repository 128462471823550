/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Membership_Babies } from '../models/Membership_Babies';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MembershipBabyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Membership_Babies Return Membership_Babies object
     * @throws ApiError
     */
    public getMembershipBaby({
        domain,
    }: {
        domain: string,
    }): CancelablePromise<Membership_Babies> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/membership/{domain}/baby',
            path: {
                'domain': domain,
            },
        });
    }

    /**
     * @returns Membership_Babies Return Membership_Babies object
     * @throws ApiError
     */
    public updateMembershipBaby({
        domain,
        requestBody,
    }: {
        domain: string,
        requestBody: Membership_Babies,
    }): CancelablePromise<Membership_Babies> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/membership/{domain}/baby',
            path: {
                'domain': domain,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
